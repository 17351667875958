// Importy knihoven
import { moon, solar } from 'astronomia';
import { DateTime } from 'luxon';

// Import vlastních komponent
import DailyNotificationCard from './DailyNotificationCard';

document.addEventListener('DOMContentLoaded', () => {
    const zodiacButtons = document.querySelectorAll('.zodiac-button');
    const quantumCards = document.querySelectorAll('.quantum-card');
    const modal = document.getElementById('horoscopeModal');
    const modalContent = modal ? modal.querySelector('.modal-content') : null;
    const modalText = document.getElementById('modalHoroscopeText');
    const closeModalBtn = modal ? modal.querySelector('.close-modal') : null;
    const birthDateInput = document.getElementById('birthDate');
    const getProphecyBtn = document.getElementById('getProphecy');
    const prophecyResultDiv = document.getElementById('prophecyResult');
    const select1 = document.getElementById('sign1');
    const select2 = document.getElementById('sign2');
    const compatibilityCard = document.querySelector('.compatibility-card');
    const compatibilityResultDiv = compatibilityCard ? compatibilityCard.querySelector('.compatibility-result') : null;
    const calculateCompatibilityBtn = compatibilityCard ? compatibilityCard.querySelector('.btn') : null;
    const particlesContainer = document.getElementById('particle-container');
    const notificationContainer = document.createElement('div');
    notificationContainer.className = 'notification-container';
    document.body.appendChild(notificationContainer);

    // Inicializace denní notifikační karty
    const dailyNotificationCard = new DailyNotificationCard();

    if (select1) select1.addEventListener('change', clearCompatibilityResult);
    if (select2) select2.addEventListener('change', clearCompatibilityResult);

    // ZÁSADNÍ ZMĚNA: Odstranění resetu a duplicitního nastavení události tlačítka Kompatibility
    /*
    const compatibilityBtn = document.querySelector('.compatibility-card .btn');
    if (compatibilityBtn) {
        // Odstraníme všechny existující event listenery (pro jistotu)
        const newCompatibilityBtn = compatibilityBtn.cloneNode(true);
        compatibilityBtn.parentNode.replaceChild(newCompatibilityBtn, compatibilityBtn);
        
        // Přidáme nový event listener
        newCompatibilityBtn.addEventListener('click', () => {
            const s1 = document.getElementById('sign1');
            const s2 = document.getElementById('sign2');
            
            if (!s1 || !s2) {
                showNotification('Nelze najít selecty pro znamení zvěrokruhu.');
                return;
            }
            
            const sign1Value = s1.value;
            const sign2Value = s2.value;
            
            if (!sign1Value || !sign2Value) {
                showNotification('Prosím, vyberte obě znamení.');
                return;
            }
            
            console.log(`Výpočet kompatibility pro: ${sign1Value} a ${sign2Value}`);
            
            const card = newCompatibilityBtn.closest('.compatibility-card');
            if (card) {
                fetchCompatibility(sign1Value, sign2Value, card);
            } else {
                console.error("Nelze najít kartu kompatibility");
            }
        });
        console.log("Připojeno nové tlačítko kompatibility");
    }
    */

    function showNotification(message, duration = 3000) {
        const notification = document.createElement('div');
        notification.className = 'notification';
        notification.textContent = message;
        notificationContainer.appendChild(notification);

        setTimeout(() => {
            notification.style.animation = 'slideOut 0.5s ease forwards';
            notification.addEventListener('animationend', () => {
                notification.remove();
            });
        }, duration);
    }

    // Sjednocená funkce showModal pro horoskopy i kompatibilitu
    function showModal(contentHTML) {
        console.log("--- showModal: Vstupní contentHTML ---", contentHTML);
        const modal = document.getElementById('horoscopeModal');
        const modalContentContainer = modal.querySelector('.modal-content');

        if (!modal || !modalContentContainer) {
            console.error("Modální okno nebo jeho kontejner .modal-content nenalezen!");
            return;
        }

        let luckySectionHTML = '';
        let finalContentHTML = contentHTML; // Začneme s původním HTML

        // 1. Extrahovat lucky data a odstranit atribut data-lucky z HTML *řetězce*
        const luckyRegex = /(<div[^>]*?)data-lucky=(['"])(.*?)\2([^>]*?>)/;
        const match = contentHTML.match(luckyRegex);

        if (match && match[3]) {
            // Nahradit HTML entity &quot; zpět na " pro JSON parser
            const luckyDataJSON = match[3].replace(/&quot;/g, '"'); 
            try {
                const luckyData = JSON.parse(luckyDataJSON);
                if (Object.keys(luckyData).length > 0) {
                    luckySectionHTML = renderLuckySection(luckyData);
                }
                // Odstranit samotný atribut data-lucky='...' z HTML řetězce
                finalContentHTML = contentHTML.replace(luckyRegex, '$1$4'); // $1 = začátek divu, $4 = zbytek tagu
            } catch (e) {
                console.error('Chyba při parsování lucky dat z atributu:', e, luckyDataJSON);
                // Pokud parsování selže, ponecháme původní finalContentHTML (bez lucky sekce)
            }
        }

        // 2. Zkontrolovat potřebu zavíracího tlačítka (v budoucím obsahu)
        let closeButtonHTML = '';
        const combinedCheckDiv = document.createElement('div');
        combinedCheckDiv.innerHTML = luckySectionHTML + finalContentHTML; // Použijeme upravený obsah
        if (!combinedCheckDiv.querySelector('.close-modal')) {
            closeButtonHTML = '<span class="close-modal" style="position: absolute; top: 15px; right: 15px; width: 30px; height: 30px; border-radius: 50%; border: 2px solid rgba(255, 255, 255, 0.2); background: rgba(255, 255, 255, 0.1); color: white; font-size: 20px; line-height: 26px; text-align: center; z-index: 2001; display: flex; align-items: center; justify-content: center; cursor: pointer;">×</span>';
        }

        // 3. Vložit finální sestavené HTML
        modalContentContainer.innerHTML = closeButtonHTML + luckySectionHTML + finalContentHTML;

        // 4. Zpracovat třídu .compatibility-modal (znovu zkontrolovat obsah po vložení)
        if (modalContentContainer.querySelector('.compatibility-modal')) {
            modalContentContainer.classList.add('compatibility-modal');
        } else {
            modalContentContainer.classList.remove('compatibility-modal');
        }

        console.log("Zobrazuji modální okno:", modal);

        // Zobrazit modální okno
        modal.style.display = 'flex';
        setTimeout(() => modal.classList.add('active'), 10);
        document.body.style.overflow = 'hidden';

        // Přidat event listenery pro zavření
        const closeBtn = modalContentContainer.querySelector('.close-modal'); 
        if (closeBtn) {
            const newCloseBtn = closeBtn.cloneNode(true);
            closeBtn.parentNode.replaceChild(newCloseBtn, closeBtn);
            newCloseBtn.addEventListener('click', closeHoroscopeModal);
        }

        modal.removeEventListener('click', closeModalOnClickOutside);
        modal.addEventListener('click', closeModalOnClickOutside);

        document.removeEventListener('keydown', escKeyListener);
        document.addEventListener('keydown', escKeyListener);
    }
    
    // Pomocná funkce pro zavření kliknutím mimo
    function closeModalOnClickOutside(event) {
        const modal = document.getElementById('horoscopeModal');
        if (event.target === modal) {
            closeHoroscopeModal();
        }
    }

    function closeHoroscopeModal() {
        const modal = document.getElementById('horoscopeModal');
        console.log("Zavírám modální okno:", modal);
        if (modal) {
            // Odebrat třídu 'active' a skrýt modální okno po dokončení animace
            modal.classList.remove('active');
            setTimeout(() => {
                modal.style.display = 'none';
                document.body.style.overflow = '';
                document.removeEventListener('keydown', escKeyListener);
                console.log("Modální okno zavřeno");
            }, 300); // 300ms odpovídá délce CSS transition v style.css
        } else {
            console.error("Modální okno nenalezeno při zavírání");
        }
    }

    function escKeyListener(event) {
        if (event.key === 'Escape') {
            closeHoroscopeModal();
        }
    }

    function createParticles(rect) {
        const localParticlesContainer = document.getElementById('particle-container');
        if (!localParticlesContainer || !rect) return;
        localParticlesContainer.innerHTML = '';

        for (let i = 0; i < 30; i++) {
            const particle = document.createElement('div');
            particle.className = 'particle';
            const size = Math.random() * 8 + 2;
            particle.style.width = `${size}px`;
            particle.style.height = `${size}px`;

            const angle = Math.random() * Math.PI * 2;
            const radius = Math.random() * 50 + Math.max(rect.width, rect.height) / 1.8;
            const x = rect.left + rect.width / 2 + radius * Math.cos(angle) - size / 2;
            const y = rect.top + rect.height / 2 + radius * Math.sin(angle) - size / 2;

            particle.style.left = `${x}px`;
            particle.style.top = `${y + window.scrollY}px`;

            const duration = Math.random() * 0.5 + 0.5;
            const delay = Math.random() * 0.2;
            particle.style.animation = `particleFade ${duration}s ease-out ${delay}s forwards`;

            localParticlesContainer.appendChild(particle);

            particle.addEventListener('animationend', () => {
                particle.remove();
            });
        }
    }

    function showLoading(cardElement) {
        if (!cardElement) return;
        
        // Použijeme funkci showCardLoading z loader.js, pokud je dostupná
        if (typeof window.showCardLoading === 'function') {
            window.showCardLoading(cardElement);
        } else {
            // Záložní řešení pro případ, kdy není k dispozici loader.js
            console.warn('showCardLoading není k dispozici, používám náhradní řešení');
            cardElement.classList.add('loading');
            
            // Vytvoříme a přidáme nový loader
            const loaderHTML = `
              <div class="loader-wrapper" style="display: flex; justify-content: center; align-items: center; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10;">
                <div class="loader" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"></div>
              </div>
            `;
            
            // Přidáme loader do karty
            cardElement.insertAdjacentHTML('beforeend', loaderHTML);
        }
    }

    function hideLoading(cardElement) {
        if (!cardElement) return;
        
        // Použijeme funkci hideCardLoading z loader.js, pokud je dostupná
        if (typeof window.hideCardLoading === 'function') {
            window.hideCardLoading(cardElement);
        } else {
            // Záložní řešení pro případ, kdy není k dispozici loader.js
            console.warn('hideCardLoading není k dispozici, používám náhradní řešení');
            cardElement.classList.remove('loading');
            
            // Najdeme a odstraníme loader
            const loaderWrapper = cardElement.querySelector('.loader-wrapper');
            if (loaderWrapper) {
                loaderWrapper.remove();
            }
        }
    }

    quantumCards.forEach(card => {
        let currentX = 0;
        let currentY = 0;
        let raf;
        const effectIntensity = parseFloat(card.getAttribute('data-effect-intensity') || '1.0');

        card.addEventListener('mousemove', e => {
            const rect = card.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            const centerX = rect.width / 2;
            const centerY = rect.height / 2;
            const targetX = ((centerY - y) / 20) * effectIntensity;
            const targetY = ((x - centerX) / 20) * effectIntensity;
            const lerp = (start, end, factor) => start + (end - start) * factor;

            const animate = () => {
                currentX = lerp(currentX, targetX, 0.2);
                currentY = lerp(currentY, targetY, 0.2);
                card.style.transform = `
                    perspective(1000px)
                    rotateX(${currentX}deg)
                    rotateY(${currentY}deg)
                    translateY(${card.matches(':hover') ? '-5px' : '0px'})
                `;
                if (Math.abs(currentX - targetX) > 0.01 || Math.abs(currentY - targetY) > 0.01) {
                    raf = requestAnimationFrame(animate);
                }
            };

            cancelAnimationFrame(raf);
            raf = requestAnimationFrame(animate);
        });

        card.addEventListener('mouseleave', () => {
            cancelAnimationFrame(raf);
            card.style.transform = `
                perspective(1000px)
                rotateX(0deg)
                rotateY(0deg)
                translateY(0px)
            `;
            currentX = 0;
            currentY = 0;
        });
    });

    zodiacButtons.forEach(button => {
        const img = button.querySelector('img');
        const sign = button.dataset.sign.toUpperCase();
        const signLower = button.dataset.sign;

        button.addEventListener('mouseenter', () => {
            if (!button.classList.contains('active')) {
                img.src = `https://taurot.com/admin/uploads/${sign}_hover.png`;
            }
        });

        button.addEventListener('mouseleave', () => {
            if (!button.classList.contains('active')) {
                img.src = `https://taurot.com/admin/uploads/${sign}_off.png`;
            }
        });

        button.addEventListener('click', () => {
            document.querySelectorAll('.zodiac-button').forEach(btn => {
                btn.classList.remove('active');
                const btnImg = btn.querySelector('img');
                const btnSign = btn.dataset.sign.toUpperCase();
                btnImg.src = `https://taurot.com/admin/uploads/${btnSign}_off.png`;
            });

            button.classList.add('active');
            img.src = `https://taurot.com/admin/uploads/${sign}_on.png`;

            updateAllHoroscopes(signLower);
        });
    });

    async function updateAllHoroscopes(sign) {
        try {
            const cards = Array.from(document.querySelectorAll('.ai-grid .quantum-card')).slice(0, 3);
            if (cards.length < 3) {
                console.error("Nebyly nalezeny první tři karty horoskopu v .ai-grid");
                return;
            }

            cards.forEach(card => {
                showLoading(card);
                const paragraph = card.querySelector('p');
                if (paragraph) {
                    paragraph.classList.add('fade-out');
                    paragraph.classList.remove('initial-text');
                    paragraph.style.filter = 'none';
                }
                
                // Deaktivujeme tlačítka během načítání
                const btn = card.querySelector('.btn');
                if (btn) {
                    btn.disabled = true;
                    btn.style.opacity = '0.5';
                    btn.style.cursor = 'not-allowed';
                }
            });

            const types = ['daily', 'weekly', 'monthly'];
            const results = await Promise.all(
                types.map(type => fetchHoroscope(sign, type))
            );

            results.forEach((text, index) => {
                setTimeout(() => {
                    const card = cards[index];
                    hideLoading(card);
                    if (text) {
                        updateHoroscope(card, text);
                        
                        // Aktivujeme tlačítko po úspěšném načtení
                        const btn = card.querySelector('.btn');
                        if (btn) {
                            btn.disabled = false;
                            btn.style.opacity = '1';
                            btn.style.cursor = 'pointer';
                        }
                    } else {
                        const paragraph = card.querySelector('p');
                        if(paragraph) {
                            paragraph.textContent = 'Chyba načítání horoskopu.';
                            paragraph.classList.remove('fade-out', 'fade-in');
                        }
                    }
                }, 300 + index * 100);
            });

        } catch (error) {
            console.error('Chyba při načítání všech horoskopů:', error);
            showNotification('Chyba', 'Nepodařilo se načíst horoskopy. Zkuste to prosím později.');
            Array.from(document.querySelectorAll('.ai-grid .quantum-card')).slice(0, 3).forEach(card => {
                hideLoading(card);
                const paragraph = card.querySelector('p');
                if (paragraph) {
                    paragraph.textContent = 'Chyba načítání.';
                    paragraph.classList.remove('fade-out', 'fade-in', 'initial-text');
                    paragraph.style.filter = 'none';
                }
                
                // Necháme tlačítka deaktivovaná při chybě
                const btn = card.querySelector('.btn');
                if (btn) {
                    btn.disabled = true;
                    btn.style.opacity = '0.5';
                    btn.style.cursor = 'not-allowed';
                }
            });
        }
    }

    function updateHoroscope(card, text) {
        const paragraph = card.querySelector('p');
        if (!card || !paragraph) return;

        const titleElement = card.querySelector('h2');
        const titleText = titleElement ? titleElement.textContent.toLowerCase() : '';
        if (titleText.includes('kalendář') || titleText.includes('kompatibilita')) {
            return;
        }

        // Uložit celý text horoskopu pro pozdější zobrazení
        card.dataset.fullText = text;

        paragraph.classList.remove('initial-text');
        paragraph.style.filter = 'none';
        paragraph.classList.add('fade-out');

        setTimeout(() => {
            // Vytvořit pomocný div pro extrakci prostého textu
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = text;
            const plainText = tempDiv.textContent || tempDiv.innerText || "";

            // Zkrátit text pro náhled (pokud je delší než 150 znaků)
            const previewText = plainText.length > 150
                ? plainText.substring(0, plainText.lastIndexOf(' ', 150)) + '...'
                : plainText;

            paragraph.textContent = previewText;
            paragraph.classList.remove('fade-out');
            paragraph.classList.add('fade-in');

            setTimeout(() => {
                paragraph.classList.remove('fade-in');
            }, 500);
        }, 300);
    }

    document.querySelectorAll('.ai-grid .quantum-card .btn').forEach(btn => {
        // Na začátku deaktivujeme tlačítka pro karty horoskopů
        const card = btn.closest('.quantum-card');
        if (card) {
            const titleElement = card.querySelector('h2');
            const titleText = titleElement ? titleElement.textContent.toLowerCase() : '';
            
            if (!titleText.includes('kalendář') && !titleText.includes('kompatibilita')) {
                // Deaktivujeme tlačítka horoskopů na začátku
                btn.disabled = true;
                btn.style.opacity = '0.5';
                btn.style.cursor = 'not-allowed';
            }
        }

        btn.addEventListener('click', () => {
            console.log("Kliknuto na tlačítko:", btn);
            const card = btn.closest('.quantum-card');
            if (!card) {
                console.error("Karta nebyla nalezena!");
                return;
            }

            const titleElement = card.querySelector('h2');
            const titleText = titleElement ? titleElement.textContent.toLowerCase() : '';
            console.log("Titulek karty:", titleText);

            if (titleText.includes('kalendář') || titleText.includes('kompatibilita')) {
                console.log("Karta je kalendář nebo kompatibilita, ignoruji...");
                return;
            }

            // Pokud má karta fullText nebo je tlačítko povolené, zobrazíme modální okno
            const fullText = card.dataset.fullText;
            console.log("Plný text v kartě:", fullText ? "ANO, délka: " + fullText.length : "NE");
            
            if (fullText) {
                console.log("Zobrazuji modální okno s textem");
                showModal(fullText);
            } else {
                const originalParagraph = card.querySelector('p');
                if (originalParagraph && originalParagraph.dataset.text) {
                    showNotification('Vyberte nejprve znamení zvěrokruhu.', 3000);
                } else {
                    showNotification('Obsah není k dispozici.', 3000);
                }
            }
        });
    });

    async function fetchHoroscope(sign, type = 'daily') {
        try {
            const response = await fetch(`api/horoscope.php?sign=${encodeURIComponent(sign)}&type=${encodeURIComponent(type)}`);

            if (!response.ok) {
                let errorMsg = `Chyba serveru: ${response.status}`;
                try {
                    const errorData = await response.json();
                    errorMsg = errorData.error || errorMsg;
                } catch (e) { /* Ignorujeme chybu parsování, pokud odpověď není JSON */ }
                throw new Error(errorMsg);
            }

            const data = await response.json();
            if (!data.success) {
                throw new Error(data.error || 'Neznámá chyba');
            }
            return data.horoscope;

        } catch (error) {
            console.error(`Chyba při načítání ${type} horoskopu pro ${sign}:`, error);
            return null;
        }
    }

    if (getProphecyBtn) {
        getProphecyBtn.addEventListener('click', () => {
            if (!birthDateInput) return;
            const birthDate = birthDateInput.value;
            if (!birthDate) {
                showNotification('Prosím, zadejte datum narození.');
                return;
            }
            const birthForm = getProphecyBtn.closest('.birth-form') || getProphecyBtn.closest('.quantum-card');
            if (birthForm) fetchProphecy(birthDate, birthForm);
        });
    }

    async function fetchProphecy(birthDate, cardElement) {
        const localProphecyResultDiv = document.getElementById('prophecyResult');
        showLoading(cardElement);
        if (localProphecyResultDiv) {
            localProphecyResultDiv.innerHTML = '';
            localProphecyResultDiv.style.display = 'none';
        }

        try {
            const response = await fetch('api/prophecy.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: `birth_date=${encodeURIComponent(birthDate)}`
            });

            if (!response.ok) {
                const errorData = await response.json().catch(() => null);
                const errorMessage = errorData?.error || `Server vrátil chybu: ${response.status}.`;
                throw new Error(errorMessage);
            }
            const data = await response.json();
            if (data.error) throw new Error(data.error);

            displayProphecy(data, birthDate);

        } catch (error) {
            console.error('Chyba při získávání proroctví:', error);
            displayErrorInCard(cardElement, error.message || 'Nepodařilo se získat proroctví.');
        } finally {
            hideLoading(cardElement);
        }
    }

    function displayProphecy(data, birthDate) {
        const localProphecyResultDiv = document.getElementById('prophecyResult');
        if (!localProphecyResultDiv) return;
        const formattedDate = new Date(birthDate).toLocaleDateString('cs-CZ');
        localProphecyResultDiv.innerHTML = `
            <div class="prophecy-result content-update">
                <div class="prophecy-date">Proroctví pro ${formattedDate}</div>
                <div class="prophecy-message">${data.prophecy}</div>
                <div class="prophecy-details">
                    <span>Znamení: ${data.sign_cz} (${data.sign})</span>
                    <span>Element: ${data.element}</span>
                    <span>Vládnoucí planeta: ${data.ruling_planet}</span>
                 </div>
            </div>
        `;
        localProphecyResultDiv.style.display = 'block';
    }

    if (calculateCompatibilityBtn) {
        calculateCompatibilityBtn.addEventListener('click', () => {
            if (!select1 || !select2) {
                showNotification('Prosím, vyberte obě znamení.');
                return;
            }
            
            const sign1Value = select1.value;
            const sign2Value = select2.value;

            if (!sign1Value || !sign2Value) {
                showNotification('Prosím, vyberte obě znamení.');
                return;
            }
            
            console.log(`Výpočet kompatibility pro: ${sign1Value} a ${sign2Value}`);
            
            const localCompatibilityCard = calculateCompatibilityBtn.closest('.compatibility-card');
            if (localCompatibilityCard) {
                fetchCompatibility(sign1Value, sign2Value, localCompatibilityCard);
            } else {
                console.error("Nelze najít nadřazenou kartu kompatibility");
            }
        });
    } else {
        // Pokud tlačítko není nalezeno standardním způsobem, hledáme ho jinak
        const compatibilityBtn = document.querySelector('.compatibility-card .btn');
        if (compatibilityBtn) {
            compatibilityBtn.addEventListener('click', () => {
                const compatibilityCard = compatibilityBtn.closest('.compatibility-card');
                if (!compatibilityCard) {
                    console.error("Nelze najít kartu kompatibility");
                    return;
                }
                
                const s1 = document.getElementById('sign1');
                const s2 = document.getElementById('sign2');
                
                if (!s1 || !s2) {
                    showNotification('Nelze najít selecty pro znamení zvěrokruhu.');
                    return;
                }
                
                const sign1Value = s1.value;
                const sign2Value = s2.value;
                
                if (!sign1Value || !sign2Value) {
                    showNotification('Prosím, vyberte obě znamení.');
                    return;
                }
                
                console.log(`Výpočet kompatibility pro: ${sign1Value} a ${sign2Value}`);
                fetchCompatibility(sign1Value, sign2Value, compatibilityCard);
            });
            console.log("Připojen alternativní event listener pro tlačítko kompatibility");
        } else {
            console.error("Nelze najít tlačítko kompatibility v DOM");
        }
    }

    function getSignInCzech(englishSign) {
        const signMap = {
            'aries': 'beran',
            'taurus': 'byk',
            'gemini': 'blizenci',
            'cancer': 'rak',
            'leo': 'lev',
            'virgo': 'panna',
            'libra': 'vahy',
            'scorpio': 'stir',
            'sagittarius': 'strelec',
            'capricorn': 'kozoroh',
            'aquarius': 'vodnar',
            'pisces': 'ryby'
        };
        
        return signMap[englishSign.toLowerCase()] || englishSign;
    }

    async function fetchCompatibility(sign1, sign2, cardElement) {
        showLoading(cardElement);
        
        try {
            // Převést anglické názvy znamení na české pro API
            const czechSign1 = getSignInCzech(sign1);
            const czechSign2 = getSignInCzech(sign2);
            
            console.log(`Konvertuji znamení: ${sign1} -> ${czechSign1}, ${sign2} -> ${czechSign2}`);
            
            // Pokusíme se načíst data nejprve z API
            try {
                // Přidáme timeout pro fetch, aby se požadavek nezasekl
                const controller = new AbortController();
                const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 sekund timeout
                
                // Použijeme relativní cestu bez lomítka na začátku
                const response = await fetch(`api/compatibility.php?sign1=${czechSign1}&sign2=${czechSign2}`, {
                    signal: controller.signal
                });
                
                clearTimeout(timeoutId); // Zrušíme timeout
                
                if (response.ok) {
                    const data = await response.json();
                    console.log("Odpověď z API kompatibility:", data);
                    
                    if (!data.error) {
                        // Zkontrolujeme strukturu dat - pokud máme compatibility objekt, použijeme ho
                        const compatibilityData = data.compatibility || data;
                        
                        // Přidáme české názvy pokud nejsou už součástí dat
                        if (!compatibilityData.sign1_cz && compatibilityData.sign1) {
                            compatibilityData.sign1_cz = getCzechSignName(compatibilityData.sign1);
                        }
                        
                        if (!compatibilityData.sign2_cz && compatibilityData.sign2) {
                            compatibilityData.sign2_cz = getCzechSignName(compatibilityData.sign2);
                        }
                        
                        // Pokud je to starý formát s data.signs, převedeme na nový formát
                        if (data.signs) {
                            compatibilityData.sign1 = data.signs.sign1.code;
                            compatibilityData.sign2 = data.signs.sign2.code;
                            compatibilityData.sign1_cz = data.signs.sign1.name;
                            compatibilityData.sign2_cz = data.signs.sign2.name;
                        }
                        
                        // Přidáme původní anglické názvy znamení pro správné URL obrázků
                        compatibilityData.sign1_en = sign1;
                        compatibilityData.sign2_en = sign2;
                        
                        // ZÁSADNÍ ZMĚNA: Získáme HTML a zobrazíme ho v jednotném modálu
                        const compatibilityHTML = displayCompatibility(compatibilityData);
                        showModal(compatibilityHTML);
                        return;
                    }
                }
                
                // Pokud odpověď není OK, přejdeme k fallback řešení
                console.log("API vrátilo chybu nebo neplatná data, používám lokální fallback.");
                throw new Error("API nedostupné, používám lokální data");
                
            } catch (fetchError) {
                console.log("Chyba při volání API:", fetchError.message);
                // Pokračujeme k fallback řešení
            }
            
            // Fallback: Použijeme ukázkovou kompatibilitu
            console.log("Používám fallback kompatibility pro znamení:", sign1, sign2);
            
            // Získáme ukázková data
            const fallbackData = getFallbackCompatibility(sign1, sign2);
            
            if (fallbackData) {
                // Přidáme původní anglické názvy znamení pro správné URL obrázků
                fallbackData.sign1_en = sign1;
                fallbackData.sign2_en = sign2;
                
                // ZÁSADNÍ ZMĚNA: Přímo voláme displayCompatibility a nepoužíváme showModal
                displayCompatibility(fallbackData);
            } else {
                throw new Error("Nepodařilo se načíst kompatibilitu. Zkuste to prosím později.");
            }

        } catch (error) {
            console.error('Chyba při výpočtu kompatibility:', error);
            showNotification(`Chyba: ${error.message || 'Nepodařilo se načíst kompatibilitu.'}`, 5000);
        } finally {
            hideLoading(cardElement);
        }
    }

    // Funkce pro generování ukázkových dat kompatibility
    function getFallbackCompatibility(sign1, sign2) {
        // Získáme české názvy znamení
        const sign1Name = getCzechSignName(sign1);
        const sign2Name = getCzechSignName(sign2);
        
        // Vytvoříme náhodné procento kompatibility
        // Ale pro stejná znamení dáme vyšší procento, pro některé kombinace specifická procenta
        let percentage = 0;
        
        if (sign1 === sign2) {
            // Stejná znamení mají vyšší kompatibilitu
            percentage = Math.floor(Math.random() * 15) + 75; // 75-90%
        } else if ((sign1 === 'leo' && sign2 === 'aries') || (sign1 === 'aries' && sign2 === 'leo')) {
            // Beran a Lev mají vysokou kompatibilitu
            percentage = Math.floor(Math.random() * 15) + 80; // 80-95%
        } else if ((sign1 === 'taurus' && sign2 === 'virgo') || (sign1 === 'virgo' && sign2 === 'taurus')) {
            // Býk a Panna mají vysokou kompatibilitu
            percentage = Math.floor(Math.random() * 15) + 85; // 85-95%
        } else if ((sign1 === 'gemini' && sign2 === 'libra') || (sign1 === 'libra' && sign2 === 'gemini')) {
            // Blíženci a Váhy mají dobrou kompatibilitu
            percentage = Math.floor(Math.random() * 15) + 75; // 75-90%
        } else {
            // Ostatní kombinace mají různá procenta
            percentage = Math.floor(Math.random() * 50) + 40; // 40-90%
        }
        
        // Vytvoříme náhodná procenta pro kategorie
        const lovePercentage = Math.floor(Math.random() * 40) + 50; // 50-90%
        const commPercentage = Math.floor(Math.random() * 40) + 50; // 50-90%
        const valuesPercentage = Math.floor(Math.random() * 40) + 50; // 50-90%
        const trustPercentage = Math.floor(Math.random() * 40) + 50; // 50-90%
        const intimacyPercentage = Math.floor(Math.random() * 40) + 50; // 50-90%
        
        return {
            sign1: sign1,
            sign2: sign2,
            sign1_cz: sign1Name,
            sign2_cz: sign2Name,
            percentage: percentage,
            summary: `${sign1Name} a ${sign2Name} tvoří ${percentage > 70 ? 'harmonickou' : 'zajímavou'} kombinaci s potenciálem pro ${percentage > 80 ? 'vášnivý a vzrušující' : 'stabilní'} vztah. Oba jsou ${percentage > 70 ? 'ohnivá znamení' : 'rozdílná znamení'}, což znamená, že sdílejí mnoho podobných vlastností, jako je energie, nadšení a touha po dobrodružství. Silná vzájemná přitažlivost a obdiv k sobě navzájem přispívají k dynamice jejich vztahu.`,
            love_compatibility: `${sign1Name} a ${sign2Name} mají v oblasti lásky a romantiky ${lovePercentage > 70 ? 'silnou' : 'zajímavou'} dynamiku. Jejich vztah je charakterizován ${lovePercentage > 80 ? 'vášní a intenzitou' : 'vzájemným respektem'}. ${sign1Name} přináší do vztahu ${sign1 === 'leo' ? 'vřelost a velkorysost' : sign1 === 'aries' ? 'energii a iniciativu' : 'jedinečnou perspektivu'}, zatímco ${sign2Name} přispívá ${sign2 === 'leo' ? 'kreativitou a nadšením' : sign2 === 'taurus' ? 'spolehlivostí a trpělivostí' : 'svým jedinečným přístupem'}.`,
            love_compatibility_percentage: lovePercentage,
            communication: `Komunikace mezi ${sign1Name} a ${sign2Name} je ${commPercentage > 70 ? 'plynulá a otevřená' : 'občas náročná, ale obohacující'}. ${sign1Name} komunikuje ${sign1 === 'gemini' ? 'rychle a s nadšením' : sign1 === 'taurus' ? 'rozvážně a prakticky' : 'svým vlastním stylem'}, zatímco ${sign2Name} se vyjadřuje ${sign2 === 'libra' ? 'diplomaticky a harmonicky' : sign2 === 'scorpio' ? 'intenzivně a pronikavě' : 'charakteristickým způsobem'}. Jejich rozdílné komunikační styly mohou ${commPercentage > 75 ? 'vytvářet zajímavou dynamiku' : 'občas vést k nedorozuměním'}.`,
            communication_percentage: commPercentage,
            values: `V oblasti hodnot a cílů ${sign1Name} a ${sign2Name} ${valuesPercentage > 70 ? 'nacházejí mnoho společných bodů' : 'respektují vzájemné rozdíly'}. ${sign1Name} si cení ${sign1 === 'capricorn' ? 'stability a dosažení cílů' : sign1 === 'pisces' ? 'spirituality a emocionálního spojení' : 'osobní svobody a sebevyjádření'}, zatímco ${sign2Name} upřednostňuje ${sign2 === 'taurus' ? 'bezpečí a hmotné zajištění' : sign2 === 'aquarius' ? 'inovaci a společenský pokrok' : 'autenticitu a osobní růst'}. Tyto hodnoty se mohou ${valuesPercentage > 75 ? 'vzájemně doplňovat' : 'někdy střetávat, což vede k osobnímu růstu'}.`,
            values_percentage: valuesPercentage,
            trust: `Důvěra mezi ${sign1Name} a ${sign2Name} je ${trustPercentage > 75 ? 'silným základem jejich vztahu' : 'oblastí, která vyžaduje oboustranné úsilí'}. ${sign1Name} přistupuje k důvěře ${sign1 === 'scorpio' ? 's intenzitou a hloubkou' : sign1 === 'sagittarius' ? 'otevřeně a optimisticky' : 'svým charakteristickým způsobem'}, zatímco ${sign2Name} buduje důvěru ${sign2 === 'cancer' ? 'postupně a opatrně' : sign2 === 'leo' ? 's loajalitou a velkorysostí' : 'vlastním tempem'}. Jejich přístupy ${trustPercentage > 70 ? 'se vzájemně doplňují' : 'mohou občas vést k napětí, které je třeba řešit upřímnou komunikací'}.`,
            trust_percentage: trustPercentage,
            intimacy: `V oblasti intimity vytváří ${sign1Name} a ${sign2Name} ${intimacyPercentage > 75 ? 'hluboké a uspokojivé spojení' : 'dynamickou a stimulující energii'}. ${sign1Name} přináší do intimní sféry ${sign1 === 'scorpio' ? 'intenzitu a vášeň' : sign1 === 'taurus' ? 'smyslnost a trpělivost' : 'jedinečnou energii'}, zatímco ${sign2Name} přispívá ${sign2 === 'pisces' ? 'empatií a citlivostí' : sign2 === 'aries' ? 'spontánností a nadšením' : 'vlastními kvalitami'}. Jejich intimní spojení je ${intimacyPercentage > 80 ? 'jednou z nejsilnějších stránek jejich vztahu' : 'oblastí vzájemného objevování a růstu'}.`,
            intimacy_percentage: intimacyPercentage
        };
    }

    function getCzechSignName(sign) {
        const signMap = {
            aries: "Beran", taurus: "Býk", gemini: "Blíženci", cancer: "Rak",
            leo: "Lev", virgo: "Panna", libra: "Váhy", scorpio: "Štír",
            sagittarius: "Střelec", capricorn: "Kozoroh", aquarius: "Vodnář", pisces: "Ryby"
        };
        return signMap[sign.toLowerCase()] || sign;
    }

    // Funkce nyní vrací HTML string, nevytváří modal
    function displayCompatibility(data) {
        // Kontrola, že máme všechna potřebná data, nebo nastavení výchozích hodnot
        const sign1 = data.sign1_en || data.sign1 || 'aries';
        const sign2 = data.sign2_en || data.sign2 || 'aries';
        const sign1Name = data.sign1_cz || getCzechSignName(sign1);
        const sign2Name = data.sign2_cz || getCzechSignName(sign2);
        const percentage = data.percentage || 0;
        const summary = data.summary || 'Informace o kompatibilitě nejsou k dispozici.';
        
        // Převod názvů znamení na velká písmena pro URL obrázků
        const sign1Upper = sign1.toUpperCase();
        const sign2Upper = sign2.toUpperCase();
        
        // ZÁSADNÍ ZMĚNA: Vytvoříme pouze HTML obsah pro modální okno
        const modalContentHTML = `
          <div class="modal-content compatibility-modal">
              <span class="close-modal">×</span>
              
              <div class="compatibility-header">
                  <h2>Kompatibilita znamení</h2>
                  <div class="compatibility-signs">
                      <div class="compatibility-sign">
                          <img src="https://taurot.com/admin/uploads/${sign1Upper}_off.png" alt="${sign1Name}" class="zodiac-icon">
                          <div class="sign-name">${sign1Name}</div>
                      </div>
                      
                      <div class="compatibility-percentage">
                          <div class="percentage-circle">
                              <div class="filled-circle" id="craterCircle" style="background: url('https://taurot.com/admin/uploads/crater_percentage.png') center center / cover no-repeat; width: 100%; height: 100%; border-radius: 50%; position: absolute; z-index: 1;"></div>
                              <div class="percentage-text" style="font-size: 1.75rem; font-weight: bold; color: white; opacity: 0.8; text-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px, rgba(0, 0, 0, 0.5) 0px 0px 5px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 10;">${percentage}%</div>
                          </div>
                      </div>
                      
                      <div class="compatibility-sign">
                          <img src="https://taurot.com/admin/uploads/${sign2Upper}_off.png" alt="${sign2Name}" class="zodiac-icon">
                          <div class="sign-name">${sign2Name}</div>
                      </div>
                  </div>
                  
                  <div class="compatibility-summary">
                      ${summary}
                  </div>
              </div>
              
              <div class="compatibility-categories">
                  ${generateCompatibilityCategories(data)}
              </div>
          </div>
        `;
        
        return modalContentHTML;
        
        // Zbytek kódu pro přímou manipulaci s DOM modálem je odstraněn
    }

    function addDynamicStyles() {
        if (!document.getElementById('compatibility-gradient-styles')) {
            const styleElement = document.createElement('style');
            styleElement.id = 'compatibility-gradient-styles';
            styleElement.textContent = `
                .compatibility-modal { padding: 2rem; }
                .compatibility-modal h2 { text-align: center; margin-bottom: 2rem; font-size: 1.8rem; }
                .compatibility-signs { display: flex; justify-content: center; align-items: center; width: 100%; gap: 2rem; margin: 1rem 0; }
                .compatibility-sign { display: flex; flex-direction: column; align-items: center; }
                .zodiac-icon { 
                    width: 64px; 
                    height: 64px; 
                    margin-bottom: 12px; 
                }
                .sign-name { font-weight: 600; text-align: center; }
                .compatibility-percentage { display: flex; align-items: center; justify-content: center; }
                .percentage-circle { 
                    position: relative; 
                    width: 120px; 
                    height: 120px; 
                    flex-shrink: 0; 
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .percentage-text { 
                    position: absolute; 
                    top: 50%; 
                    left: 50%; 
                    transform: translate(-50%, -50%); 
                    font-size: 1.75rem !important; 
                    font-weight: bold; 
                    color: white; 
                    opacity: 0.8;
                    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5), 0 0 5px rgba(0, 0, 0, 0.5);
                    z-index: 2;
                }
                .filled-circle { 
                    width: 100%; 
                    height: 100%; 
                    border-radius: 50%; 
                    background: url('https://taurot.com/admin/uploads/crater_percentage.png') !important;
                    background-size: cover !important;
                    background-position: center !important;
                    position: absolute;
                    z-index: 1;
                }
                .compatibility-summary { 
                    margin-top: 1.5rem; 
                    padding: 1.5rem; 
                    background: rgba(255, 255, 255, 0.1); 
                    border-radius: 10px;
                    text-align: center;
                    line-height: 1.6;
                }
                .compatibility-categories { margin-top: 1.5rem; display: flex; flex-direction: column; gap: 1rem; }
                .compatibility-category { 
                    background: rgba(255, 255, 255, 0.05); 
                    border-radius: 10px; 
                    padding: 1.2rem; 
                    border-left: 3px solid; 
                    border-image: linear-gradient(to bottom, #1e88e5, #9c27b0, #e91e63) 1;
                }
                .category-header { 
                    display: flex; 
                    align-items: center; 
                    margin-bottom: 0.75rem;
                }
                .category-icon { 
                    width: 30px; 
                    height: 30px; 
                    border-radius: 50%; 
                    background: linear-gradient(135deg, #1e88e5, #9c27b0, #e91e63); 
                    display: flex; 
                    align-items: center; 
                    justify-content: center; 
                    margin-right: 0.75rem;
                }
                .category-icon i { color: white; font-size: 0.875rem; }
                .category-title { font-weight: 600; flex-grow: 1; }
                .category-percentage { font-weight: 600; color: var(--accent-color, #a855f7); }
                .category-text { line-height: 1.5; font-size: 0.95rem; }
                
                @media (max-width: 768px) {
                    .compatibility-modal { padding: 1rem; }
                    .compatibility-signs { 
                        flex-direction: row !important; 
                        flex-wrap: nowrap !important; 
                        justify-content: space-between !important; 
                        gap: 0.5rem; 
                        width: 100%;
                        margin: 1rem auto;
                    }
                    .compatibility-sign { flex: 0 1 auto; max-width: 30%; }
                    .compatibility-percentage { flex: 0 0 auto; }
                    .percentage-circle { width: 90px; height: 90px; }
                    .percentage-text { 
                        font-size: 1.4rem !important; 
                        opacity: 0.8;
                        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5), 0 0 5px rgba(0, 0, 0, 0.5);
                    }
                    .zodiac-icon { width: 48px; height: 48px; }
                    .sign-name { font-size: 14px; }
                    .compatibility-summary, .compatibility-category { padding: 0.8rem; }
                }
            `;
            document.head.appendChild(styleElement);
        }
    }

    function generateCompatibilityCategories(data) {
        // Pokud máme kategorie v starém formátu
        if (data.categories) {
            let categoriesHTML = '';
            Object.entries(data.categories).forEach(([key, category]) => {
                categoriesHTML += `
                    <div class="compatibility-category">
                        <div class="category-header">
                            <div class="category-icon"><i class="fas fa-${category.icon}"></i></div>
                            <div class="category-title">${category.title}</div>
                            <div class="category-percentage">${category.percentage}%</div>
                        </div>
                        <div class="category-text">${category.text}</div>
                    </div>
                `;
            });
            return categoriesHTML;
        }
        
        // Jinak zobrazíme nový formát s jednotlivými kategoriemi
        return `
            ${generateCategoryHTML('Láska & Romantika', data.love_compatibility, 'heart', data.love_compatibility_percentage)}
            ${generateCategoryHTML('Komunikace', data.communication, 'comments', data.communication_percentage)}
            ${generateCategoryHTML('Společné hodnoty', data.values, 'gem', data.values_percentage)}
            ${generateCategoryHTML('Důvěra', data.trust, 'shield-alt', data.trust_percentage)}
            ${generateCategoryHTML('Intimita', data.intimita || data.intimacy, 'fire', data.intimacy_percentage)}
        `;
    }

    function generateCategoryHTML(title, text, iconClass, percentage='') {
        const safeText = text || "Informace nejsou k dispozici.";
        
        // Vypnuto přidání prefixu - používáme originální hodnotu ikon
        // const iconWithPrefix = iconClass.startsWith('fa-') ? iconClass : `fa-${iconClass}`;
        
        const percentageHTML = percentage ? `<div class="category-percentage">${percentage}%</div>` : '';
        
        return `
            <div class="compatibility-category">
                <div class="category-header">
                    <div class="category-icon"><i class="fas fa-${iconClass}"></i></div>
                    <div class="category-title">${title}</div>
                    ${percentageHTML}
                </div>
                <div class="category-text">${safeText}</div>
            </div>
        `;
    }

    async function displayErrorInCard(cardElement, message) {
        if (!cardElement) return;
        const prophecyResultDiv = cardElement.querySelector('#prophecyResult');
        
        if (prophecyResultDiv) {
            prophecyResultDiv.innerHTML = `<p style="color: #ff7e7e; text-align: center; padding: 1rem;">${message}</p>`;
            prophecyResultDiv.style.display = 'block';
        } else {
            showNotification(`Chyba: ${message}`, 5000);
        }
    }

    function clearCompatibilityResult() {
        // Funkce již není potřeba, protože div nepoužíváme
    }

    function loadFontAwesome() {
        if (!document.querySelector('link[href*="fontawesome"]')) {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
            link.integrity = "sha512-Fo3rlrZj/k7ujTnHg4CGR2D7kSs0v4LLanw2qksYuRlEzO+tcaEPQogQ0KaoGN26/zrn20ImR1DfuLWnOo7aBA==";
            link.crossOrigin = "anonymous";
            link.referrerPolicy = "no-referrer";
            document.head.appendChild(link);
        }
    }

    loadFontAwesome();
    addDynamicStyles();

    function updateProgressBars() {
        const now = new Date();
        const currentHour = now.getHours() + (now.getMinutes() / 60);
        const dailyProgress = (currentHour / 24) * 100;
        const currentDay = now.getDay() || 7;
        const weeklyProgress = ((currentDay -1) * 24 + currentHour) / (7*24) * 100;
        const totalDays = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
        const currentDate = now.getDate();
        const monthlyProgress = ((currentDate -1) * 24 + currentHour) / (totalDays*24) * 100;

        document.querySelectorAll('.ai-grid .quantum-card').forEach(card => {
            const progressFill = card.querySelector('.cyber-progress .progress-fill');
            if (!progressFill) return;
            const titleElement = card.querySelector('h2');
            const title = titleElement ? titleElement.textContent : '';

            if (title.includes('Den ve hvězdách')) {
                progressFill.style.width = `${dailyProgress}%`;
            }
            else if (title.includes('Týdenní')) {
                progressFill.style.width = `${weeklyProgress}%`;
            }
            else if (title.includes('Měsíční')) {
                progressFill.style.width = `${monthlyProgress}%`;
            }
            else if (title.includes('kalendář')) {
                progressFill.style.width = '75%';
            }
        });
    }
    updateProgressBars();
    setInterval(updateProgressBars, 60000);

    function renderLuckySection(data) {
        let html = '<div class="lucky-container">';
        
        if (data.lucky_numbers && data.lucky_numbers.length > 0) {
            html += `
                <div class="lucky-group">
                    <div class="lucky-label">ŠŤASTNÁ ČÍSLA</div>
                    <div class="lucky-numbers-container">
                        ${data.lucky_numbers.map(num => `<div class="lucky-number-circle">${num}</div>`).join('')}
                    </div>
                </div>
            `;
        } else if (data.lucky_number) {
            html += `
                <div class="lucky-group">
                    <div class="lucky-label">ŠŤASTNÉ ČÍSLO</div>
                    <div class="lucky-number-circle">${data.lucky_number}</div>
                </div>
            `;
        }
        
        if (data.lucky_dates && data.lucky_dates.length > 0) {
            html += `
                <div class="lucky-group">
                    <div class="lucky-label">ŠŤASTNÁ DATA</div>
                    <div class="lucky-numbers-container">
                        ${data.lucky_dates.map(date => `<div class="lucky-number-circle">${date}</div>`).join('')}
                    </div>
                </div>
            `;
        }
        
        if (data.lucky_day) {
            html += `
                <div class="lucky-group">
                    <div class="lucky-label">ŠŤASTNÝ DEN</div>
                    <div class="lucky-day-pill">${data.lucky_day}</div>
                </div>
            `;
        }
        
        if (data.lucky_color && data.lucky_color.name && data.lucky_color.hex) {
            html += `
                <div class="lucky-group">
                    <div class="lucky-label">ŠŤASTNÁ BARVA</div>
                    <div class="lucky-color-circle" 
                         style="background-color: ${data.lucky_color.hex}"
                         title="${data.lucky_color.name}"></div> 
                    
                </div>
            `;
        }
        
        html += '</div>';
        return html;
    }

    // Funkce pro přidání bokeh efektu do modálu
    function addBokehEffect(modal) {
        // Odstraníme existující bokeh background, pokud existuje
        const existingBokeh = modal.querySelector('.bokeh-background');
        if (existingBokeh) {
            existingBokeh.remove();
        }
        
        // Vytvoříme nový bokeh background
        const bokehBackground = document.createElement('div');
        bokehBackground.className = 'bokeh-background';
        
        // Barvy bokeh kružnic - tmavší odstíny fialové s nižší průhledností
        const colors = [
            'rgba(88, 32, 169, 0.15)',
            'rgba(65, 25, 120, 0.12)',
            'rgba(122, 33, 166, 0.10)',
            'rgba(40, 15, 80, 0.15)',
            'rgba(35, 12, 65, 0.12)'
        ];
        
        // Generujeme méně bokeh kružnic (8-12)
        const numCircles = Math.floor(Math.random() * 5) + 8;
        
        for (let i = 0; i < numCircles; i++) {
            const circle = document.createElement('div');
            circle.className = 'bokeh-circle';
            
            // Větší velikost pro více rozmazaný efekt
            const size = Math.floor(Math.random() * 300) + 100;
            const left = Math.random() * 100;
            const top = Math.random() * 100;
            
            // Náhodná barva z palety
            const color = colors[Math.floor(Math.random() * colors.length)];
            
            // Pomalejší a delší animace
            const delay = Math.random() * 8;
            const duration = Math.random() * 15 + 20;
            
            // Nastavení stylů
            circle.style.width = `${size}px`;
            circle.style.height = `${size}px`;
            circle.style.left = `${left}%`;
            circle.style.top = `${top}%`;
            circle.style.backgroundColor = color;
            circle.style.animationDelay = `${delay}s`;
            circle.style.animationDuration = `${duration}s`;
            
            bokehBackground.appendChild(circle);
        }
        
        // Vložíme bokeh efekt do modálu
        modal.appendChild(bokehBackground);
    }

    // Funkce pro astrologický kalendář
    class AstroCalendar {
        constructor() {
            this.currentDate = new Date();
            this.selectedDate = new Date();
            this.events = [];
            this.currentFilter = 'all';
            this.init();
        }

        init() {
            this.initControls();
            this.initFilters();
            this.loadEvents();
            this.initGeolocation();
        }

        initControls() {
            const prevBtn = document.querySelector('.prev-month');
            const nextBtn = document.querySelector('.next-month');
            const monthDisplay = document.querySelector('.current-month');

            prevBtn.addEventListener('click', () => {
                this.currentDate.setMonth(this.currentDate.getMonth() - 1);
                this.updateCalendar();
            });

            nextBtn.addEventListener('click', () => {
                this.currentDate.setMonth(this.currentDate.getMonth() + 1);
                this.updateCalendar();
            });

            this.updateCalendar();
        }

        initFilters() {
            const filters = document.querySelectorAll('.filter-btn');
            filters.forEach(btn => {
                btn.addEventListener('click', () => {
                    filters.forEach(b => b.classList.remove('active'));
                    btn.classList.add('active');
                    this.currentFilter = btn.dataset.filter;
                    this.filterEvents();
                });
            });
        }

        async loadEvents() {
            try {
                const response = await fetch('api/calendar_events.php');
                const data = await response.json();
                if (data.success) {
                    this.events = data.events;
                    this.updateCalendar();
                }
            } catch (error) {
                console.error('Chyba při načítání událostí:', error);
            }
        }

        initGeolocation() {
            const locationDisplay = document.querySelector('.location-display');
            const updateBtn = document.querySelector('.location-update');

            const updateLocation = () => {
                if (navigator.geolocation) {
                    locationDisplay.textContent = 'Získávám polohu...';
                    navigator.geolocation.getCurrentPosition(
                        async (position) => {
                            try {
                                const response = await fetch(
                                    `api/geocode.php?lat=${position.coords.latitude}&lng=${position.coords.longitude}`
                                );
                                const data = await response.json();
                                if (data.success) {
                                    locationDisplay.textContent = data.city;
                                    this.updateEvents(position.coords);
                                }
                            } catch (error) {
                                locationDisplay.textContent = 'Chyba při získávání lokace';
                            }
                        },
                        () => {
                            locationDisplay.textContent = 'Nepodařilo se získat polohu';
                        }
                    );
                }
            };

            updateBtn.addEventListener('click', updateLocation);
            updateLocation();
        }

        updateCalendar() {
            const grid = document.querySelector('.calendar-grid');
            const monthDisplay = document.querySelector('.current-month');
            
            // Aktualizace názvu měsíce
            const months = ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 
                           'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'];
            monthDisplay.textContent = `${months[this.currentDate.getMonth()]} ${this.currentDate.getFullYear()}`;

            // Vytvoření kalendářní mřížky
            const firstDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
            const lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
            
            let html = `
                <div class="calendar-header">
                    <div>Po</div><div>Út</div><div>St</div><div>Čt</div>
                    <div>Pá</div><div>So</div><div>Ne</div>
                </div>
            `;

            let day = 1;
            let startDay = firstDay.getDay() || 7;
            
            for (let i = 0; i < 6; i++) {
                for (let j = 1; j <= 7; j++) {
                    if (i === 0 && j < startDay || day > lastDay.getDate()) {
                        html += '<div class="calendar-day empty"></div>';
                    } else {
                        const date = new Date(this.currentDate.getFullYear(), 
                                           this.currentDate.getMonth(), day);
                        const events = this.getEventsForDate(date);
                        const isToday = this.isToday(date);
                        const isSelected = this.isSelected(date);
                        
                        html += `
                            <div class="calendar-day ${isToday ? 'today' : ''} 
                                                    ${isSelected ? 'selected' : ''} 
                                                    ${events.length ? 'has-events' : ''}"
                                 data-date="${date.toISOString()}">
                                <span class="day-number">${day}</span>
                                ${this.renderEventIndicators(events)}
                            </div>
                        `;
                        day++;
                    }
                }
            }

            grid.innerHTML = html;
            this.addDayClickListeners();
            this.filterEvents();
        }

        getEventsForDate(date) {
            return this.events.filter(event => {
                const eventDate = new Date(event.date);
                return eventDate.toDateString() === date.toDateString();
            });
        }

        renderEventIndicators(events) {
            if (!events.length) return '';
            
            const types = new Set(events.map(e => e.type));
            let html = '<div class="event-indicators">';
            
            if (types.has('planets')) {
                html += '<span class="indicator planets"></span>';
            }
            if (types.has('moon')) {
                html += '<span class="indicator moon"></span>';
            }
            if (types.has('zodiac')) {
                html += '<span class="indicator zodiac"></span>';
            }
            
            html += '</div>';
            return html;
        }

        addDayClickListeners() {
            const days = document.querySelectorAll('.calendar-day:not(.empty)');
            days.forEach(day => {
                day.addEventListener('click', () => {
                    days.forEach(d => d.classList.remove('selected'));
                    day.classList.add('selected');
                    this.selectedDate = new Date(day.dataset.date);
                    this.showEvents(this.getEventsForDate(this.selectedDate));
                });
            });
        }

        showEvents(events) {
            const container = document.querySelector('.calendar-events');
            if (!events.length) {
                container.innerHTML = '<p class="no-events">Žádné události pro tento den</p>';
                return;
            }

            let html = '';
            events.forEach(event => {
                html += `
                    <div class="calendar-event ${event.type}">
                        <div class="event-icon">
                            ${this.getEventIcon(event.type)}
                        </div>
                        <div class="event-details">
                            <h4>${event.title}</h4>
                            <p>${event.description}</p>
                            <span class="event-time">${event.time || ''}</span>
                        </div>
                    </div>
                `;
            });

            container.innerHTML = html;
        }

        getEventIcon(type) {
            const icons = {
                planets: '🌍',
                moon: '🌙',
                zodiac: '♈'
            };
            return icons[type] || '✨';
        }

        filterEvents() {
            const days = document.querySelectorAll('.calendar-day:not(.empty)');
            days.forEach(day => {
                const date = new Date(day.dataset.date);
                const events = this.getEventsForDate(date);
                const filteredEvents = this.currentFilter === 'all' ? 
                    events : 
                    events.filter(e => e.type === this.currentFilter);
                
                day.classList.toggle('has-events', filteredEvents.length > 0);
                const indicators = day.querySelector('.event-indicators');
                if (indicators) {
                    indicators.innerHTML = this.renderEventIndicators(filteredEvents);
                }
            });

            if (this.selectedDate) {
                const events = this.getEventsForDate(this.selectedDate);
                const filteredEvents = this.currentFilter === 'all' ? 
                    events : 
                    events.filter(e => e.type === this.currentFilter);
                this.showEvents(filteredEvents);
            }
        }

        isToday(date) {
            const today = new Date();
            return date.toDateString() === today.toDateString();
        }

        isSelected(date) {
            return date.toDateString() === this.selectedDate.toDateString();
        }

        async updateEvents(coords) {
            try {
                const response = await fetch(
                    `api/calendar_events.php?lat=${coords.latitude}&lng=${coords.longitude}`
                );
                const data = await response.json();
                if (data.success) {
                    this.events = data.events;
                    this.updateCalendar();
                }
            } catch (error) {
                console.error('Chyba při aktualizaci událostí:', error);
            }
        }
    }

    // Kalendář byl nahrazen notifikační kartou, která se inicializuje výše
    // document.addEventListener('DOMContentLoaded', () => {
    //     const calendar = new AstroCalendar();
    // });
});