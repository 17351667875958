// Třída pro správu notifikační karty dnešního dne
export class DailyNotificationCard {
    constructor() {
        console.log("Inicializace DailyNotificationCard");
        this.cardElement = document.querySelector('.daily-notification-card');
        if (!this.cardElement) {
            console.error('Nenalezena karta denních notifikací');
            return;
        }

        // Reference na elementy v kartě
        this.dayNameElement = this.cardElement.querySelector('.day-name');
        this.fullDateElement = this.cardElement.querySelector('.full-date');
        this.moonPhaseVisual = this.cardElement.querySelector('.moon-circle');
        this.moonPhaseInfo = this.cardElement.querySelector('.moon-phase-info');
        this.cosmicMessage = this.cardElement.querySelector('.daily-cosmic-message p');
        this.locationDisplay = this.cardElement.querySelector('.location-display');
        this.locationUpdateBtn = this.cardElement.querySelector('.location-update');

        console.log("DailyNotificationCard: Reference nastaveny", {
            dayNameElement: !!this.dayNameElement,
            fullDateElement: !!this.fullDateElement, 
            moonPhaseVisual: !!this.moonPhaseVisual,
            moonPhaseInfo: !!this.moonPhaseInfo,
            cosmicMessage: !!this.cosmicMessage,
            locationDisplay: !!this.locationDisplay,
            locationUpdateBtn: !!this.locationUpdateBtn
        });

        // ZMĚNA: Okamžitě použijeme aktuální data jako výchozí stav
        const now = new Date();
        const currentDate = this.formatCurrentDate(now);
        
        // Okamžitě nastavíme aktuální datum
        if (this.dayNameElement) {
            this.dayNameElement.textContent = currentDate.dayName;
            this.dayNameElement.style.opacity = '1';
            this.dayNameElement.style.transform = 'translateY(0)';
        }
        
        if (this.fullDateElement) {
            this.fullDateElement.textContent = currentDate.fullDate;
            this.fullDateElement.style.opacity = '1';
            this.fullDateElement.style.transform = 'translateY(0)';
        }
        
        // Nastavíme aktuální fázi měsíce
        const moonPhase = this.calculateBasicMoonPhase(now);
        
        if (this.moonPhaseVisual) {
            this.updateMoonPhaseVisual(moonPhase);
            this.moonPhaseVisual.style.opacity = '1';
            this.moonPhaseVisual.style.transform = 'translateY(0)';
        }
        
        if (this.moonPhaseInfo) {
            const phaseName = this.getMoonPhaseName(moonPhase);
            const illumination = Math.round(Math.sin(moonPhase * Math.PI) * 100);
            this.moonPhaseInfo.innerHTML = `
                <span class="moon-phase-name">${phaseName}</span>
                <span class="moon-phase-percent">${illumination}% osvětlení</span>
            `;
            this.moonPhaseInfo.style.opacity = '1';
            this.moonPhaseInfo.style.transform = 'translateY(0)';
        }
        
        // Nastavíme výchozí kosmickou zprávu
        if (this.cosmicMessage) {
            const phaseName = this.getMoonPhaseName(moonPhase);
            let messageForPhase = this.getMessageForPhase(phaseName);
            this.cosmicMessage.textContent = messageForPhase;
            this.cosmicMessage.style.opacity = '1';
            this.cosmicMessage.style.transform = 'translateY(0)';
        }

        // Okamžitě inicializujeme - bez timeoutu
        this.init();

        // Automatická aktualizace o půlnoci
        this.scheduleNextUpdate();
    }

    // Nová metoda pro získání zprávy podle fáze měsíce
    getMessageForPhase(phaseName) {
        let message = "";
        switch (phaseName) {
            case "Nov":
                message = "Dnešní nov přináší nový začátek a příležitost nastavit nové záměry. Využijte tuto obnovenou energii k plánování projektů.";
                break;
            case "Dorůstající srpek":
                message = "Dorůstající srpek dnes posiluje vaši tvůrčí energii a odhodlání. Je ideální čas začít uskutečňovat své plány.";
                break;
            case "První čtvrť":
                message = "První čtvrť Měsíce vám dnes přináší energii potřebnou k překonání překážek. Je čas podniknout konkrétní kroky k cílům.";
                break;
            case "Dorůstající měsíc":
                message = "Dorůstající Měsíc zesiluje vaši manifestační sílu. Cokoli dnes tvoříte a budujete, má potenciál růst.";
                break;
            case "Úplněk":
                message = "Úplněk dnes osvětluje vše, co bylo skryto. Je čas pro vnitřní prozření a dokončení projektů. Vaše intuice je na vrcholu.";
                break;
            case "Ubývající měsíc":
                message = "Energie ubývajícího Měsíce vás dnes vede k uvolnění a odevzdání. Je ideální čas pro reflexi a zpracování emocí.";
                break;
            case "Poslední čtvrť":
                message = "Poslední čtvrť nabízí prostor pro uzavření kapitol a odpuštění. Zaměřte se na to, co chcete uvolnit ze svého života.";
                break;
            case "Ubývající srpek":
                message = "Ubývající srpek podporuje odpoutání a vnitřní očistu. Dnes je ideální čas pro zklidnění a meditaci.";
                break;
            default:
                message = "Dnešní den přináší kosmické energie, které podporují vaši intuici a kreativitu. Naslouchejte svému vnitřnímu hlasu.";
        }
        
        // Omezení zprávy na maximálně 160 znaků
        if (message.length > 160) {
            message = message.substring(0, 157) + "...";
        }
        
        return message;
    }

    init() {
        console.log("DailyNotificationCard: Inicializace");
        // Načtení dat z API - asynchronně, nezablokuje zobrazení
        this.loadDailyContent();
        
        // Nastavení geolokace
        this.initGeolocation();
    }

    async loadDailyContent() {
        console.log("DailyNotificationCard: Načítání obsahu");
        try {
            // Nepoužíváme loading stav - obsah už je zobrazen
            // this.setLoadingState(true);
            
            console.log("DailyNotificationCard: Volání API");
            
            // Použijeme nejprve přímé volání API, pak CORS proxy a nakonec fallback
            let response;
            let apiUrl;
            let data;
            let success = false;

            // Postupně zkusíme různé přístupy k API
            const methods = [
                { name: 'direct', url: 'api/daily_notification.php' },
                { name: 'cors-proxy', url: 'api/cors_proxy.php?url=api/daily_notification.php' },
                { name: 'static-json', url: 'api/daily_data.json' },
                { name: 'absolute-direct', url: window.location.origin + '/api/daily_notification.php' },
                { name: 'absolute-cors', url: window.location.origin + '/api/cors_proxy.php?url=api/daily_notification.php' },
                { name: 'absolute-json', url: window.location.origin + '/api/daily_data.json' }
            ];

            for (const method of methods) {
                if (success) break;
                
                try {
                    console.log(`DailyNotificationCard: Zkouším metodu ${method.name}: ${method.url}`);
                    response = await fetch(method.url);
                    console.log(`DailyNotificationCard: Odpověď ${method.name}:`, response.status);
                    
                    if (response.ok) {
                        const text = await response.text();
                        console.log(`DailyNotificationCard: Text odpovědi (prvních 100 znaků): ${text.substring(0, 100)}...`);
                        
                        // Zkontrolujeme, zda text obsahuje validní JSON
                        if (text && text.trim().length > 0) {
                            try {
                                data = JSON.parse(text);
                                success = true;
                                console.log(`DailyNotificationCard: Úspěšné získání dat metodou ${method.name}`);
                                break;
                            } catch (e) {
                                console.warn(`DailyNotificationCard: Metoda ${method.name} vrátila neplatný JSON:`, e);
                            }
                        }
                    }
                } catch (error) {
                    console.warn(`DailyNotificationCard: Metoda ${method.name} selhala:`, error);
                }
            }
            
            // Pokud uspějeme s API, aktualizujeme obsah - ale ten už je viditelný
            if (success && data && data.success) {
                // Nastavení obsahu
                this.setContentDirect(data);
            }
        } catch (error) {
            console.error('DailyNotificationCard: Chyba při načítání denního obsahu', error);
        } finally {
            // Ukončení loading stavu
            this.setLoadingState(false);
        }
    }

    setContentDirect(data) {
        console.log("DailyNotificationCard: Přímé nastavení obsahu", data);
        
        // Aktualizace data
        if (this.dayNameElement) {
            this.dayNameElement.textContent = data.date.dayName;
            this.dayNameElement.style.opacity = '1';
            this.dayNameElement.style.transform = 'translateY(0)';
            console.log(`DailyNotificationCard: Nastaven den: ${data.date.dayName}`);
        }
        
        if (this.fullDateElement) {
            this.fullDateElement.textContent = data.date.fullDate;
            this.fullDateElement.style.opacity = '1';
            this.fullDateElement.style.transform = 'translateY(0)';
            console.log(`DailyNotificationCard: Nastaveno datum: ${data.date.fullDate}`);
        }
        
        // Aktualizace fáze měsíce
        if (this.moonPhaseVisual) {
            this.updateMoonPhaseVisual(data.moon.phase);
            this.moonPhaseVisual.style.opacity = '1';
            this.moonPhaseVisual.style.transform = 'translateY(0)';
            console.log(`DailyNotificationCard: Nastavena fáze měsíce: ${data.moon.phase}`);
        }
        
        if (this.moonPhaseInfo) {
            // Upravíme formát zobrazení fáze měsíce
            this.moonPhaseInfo.innerHTML = `
                <span class="moon-phase-name">${data.moon.phaseName}</span>
                <span class="moon-phase-percent">${data.moon.illumination}% osvětlení</span>
            `;
            this.moonPhaseInfo.style.opacity = '1';
            this.moonPhaseInfo.style.transform = 'translateY(0)';
            console.log(`DailyNotificationCard: Nastaven text fáze: ${data.moon.phaseName}`);
        }
        
        // Aktualizace kosmické zprávy s omezením na 160 znaků
        if (this.cosmicMessage) {
            let message = data.message || "";
            
            // Striktní kontrola délky zprávy - vždy ořezat na 160 znaků
            if (message.length > 160) {
                message = message.substring(0, 157) + "...";
                console.log("DailyNotificationCard: Zpráva byla zkrácena na 160 znaků");
            }
            
            this.cosmicMessage.textContent = message;
            this.cosmicMessage.style.opacity = '1';
            this.cosmicMessage.style.transform = 'translateY(0)';
            console.log("DailyNotificationCard: Nastavena zpráva o délce", message.length);
        }
    }

    setLoadingState(isLoading) {
        if (isLoading) {
            if (typeof window.showCardLoading === 'function') {
                window.showCardLoading(this.cardElement);
            } else {
                // Záložní řešení, pokud není k dispozici showCardLoading
                console.warn('showCardLoading není k dispozici, používám náhradní řešení');
                this.cardElement.classList.add('loading');
                
                // Vytvoříme a přidáme nový loader
                const loaderHTML = `
                  <div class="loader-wrapper" style="display: flex; justify-content: center; align-items: center; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10;">
                    <div class="loader" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"></div>
                  </div>
                `;
                
                // Přidáme loader do karty notifikace
                this.cardElement.insertAdjacentHTML('beforeend', loaderHTML);
            }
        } else {
            if (typeof window.hideCardLoading === 'function') {
                window.hideCardLoading(this.cardElement);
            } else {
                // Záložní řešení, pokud není k dispozici hideCardLoading
                console.warn('hideCardLoading není k dispozici, používám náhradní řešení');
                this.cardElement.classList.remove('loading');
                
                // Najdeme a odstraníme loader
                const loaderWrapper = this.cardElement.querySelector('.loader-wrapper');
                if (loaderWrapper) {
                    loaderWrapper.remove();
                }
            }
        }
    }

    animateContent() {
        // Přidat animace pro plynulé zobrazení obsahu
        const elements = [
            this.dayNameElement, 
            this.fullDateElement, 
            this.moonPhaseVisual, 
            this.moonPhaseInfo, 
            this.cosmicMessage
        ];
        
        elements.forEach((el, index) => {
            if (!el) return;
            
            // Nejprve resetujeme styl
            el.style.opacity = '0';
            el.style.transform = 'translateY(20px)';
            
            // Poté animujeme každý prvek s malým zpožděním
            setTimeout(() => {
                el.style.transition = 'opacity 0.5s ease, transform 0.5s ease';
                el.style.opacity = '1';
                el.style.transform = 'translateY(0)';
            }, 100 * index);
        });
    }

    setFallbackContent() {
        console.log("DailyNotificationCard: Používám fallback obsah");

        // Nejprve se pokusím načíst fallback data z inline JSON v HTML
        try {
            const fallbackElement = document.getElementById('daily-notification-fallback');
            if (fallbackElement) {
                console.log("DailyNotificationCard: Našel jsem fallback element", fallbackElement);
                let fallbackData;
                try {
                    fallbackData = JSON.parse(fallbackElement.textContent);
                    console.log("DailyNotificationCard: Parsovaná fallback data", fallbackData);
                    
                    // Aktualizace dat s aktuálním datem
                    const now = new Date();
                    const currentDate = this.formatCurrentDate(now);
                    fallbackData.date = {
                        dayName: currentDate.dayName,
                        fullDate: currentDate.fullDate
                    };
                    
                    // Kontrola struktury dat
                    if (fallbackData && fallbackData.success && 
                        fallbackData.date && fallbackData.moon && 
                        fallbackData.message) {
                        console.log("DailyNotificationCard: Fallback data jsou validní, použiji je");
                        this.setContentDirect(fallbackData);
                        return;
                    } else {
                        console.warn("DailyNotificationCard: Fallback data nemají očekávanou strukturu");
                    }
                } catch (parseError) {
                    console.error("DailyNotificationCard: Nelze parsovat fallback data", parseError);
                }
            } else {
                console.warn("DailyNotificationCard: Fallback element nebyl nalezen");
            }
        } catch (error) {
            console.warn("DailyNotificationCard: Chyba při načítání inline fallback dat", error);
        }
        
        // Záložní obsah v případě chyby při načítání API
        console.log("DailyNotificationCard: Použiji automaticky generovaný fallback obsah");
        const now = new Date();
        
        // Formátování data s aktuálními informacemi
        const currentDate = this.formatCurrentDate(now);
        
        // Nastavení textů - přímé nastavení stylů pro okamžité zobrazení
        if (this.dayNameElement) {
            this.dayNameElement.textContent = currentDate.dayName;
            this.dayNameElement.style.opacity = '1';
            this.dayNameElement.style.transform = 'translateY(0)';
            console.log(`DailyNotificationCard: Fallback nastavení dne: ${currentDate.dayName}`);
        }
        
        if (this.fullDateElement) {
            this.fullDateElement.textContent = currentDate.fullDate;
            this.fullDateElement.style.opacity = '1';
            this.fullDateElement.style.transform = 'translateY(0)';
            console.log(`DailyNotificationCard: Fallback nastavení data: ${currentDate.fullDate}`);
        }
        
        // Zjednodušený výpočet fáze měsíce pro aktuální datum
        const moonPhase = this.calculateBasicMoonPhase(now);
        
        if (this.moonPhaseVisual) {
            this.updateMoonPhaseVisual(moonPhase);
            this.moonPhaseVisual.style.opacity = '1';
            this.moonPhaseVisual.style.transform = 'translateY(0)';
            console.log(`DailyNotificationCard: Fallback nastavení vizuálu měsíce: ${moonPhase}`);
        }
        
        if (this.moonPhaseInfo) {
            const phaseName = this.getMoonPhaseName(moonPhase);
            const illumination = Math.round(Math.sin(moonPhase * Math.PI) * 100);
            this.moonPhaseInfo.innerHTML = `
                <span class="moon-phase-name">${phaseName}</span>
                <span class="moon-phase-percent">${illumination}% osvětlení</span>
            `;
            this.moonPhaseInfo.style.opacity = '1';
            this.moonPhaseInfo.style.transform = 'translateY(0)';
            console.log(`DailyNotificationCard: Fallback nastavení info o měsíci: ${phaseName}, ${illumination}%`);
        }
        
        // Generujeme zprávu na základě fáze měsíce
        if (this.cosmicMessage) {
            const phaseName = this.getMoonPhaseName(moonPhase);
            let messageForPhase = "";
            
            switch (phaseName) {
                case "Nov":
                    messageForPhase = "Dnešní nov přináší nový začátek a příležitost nastavit nové záměry. Využijte tuto obnovenou energii k plánování projektů a vizualizaci svých cílů. Dopřejte si čas na meditaci a vnitřní klid.";
                    break;
                case "Dorůstající srpek":
                    messageForPhase = "Dorůstající srpek dnes posiluje vaši tvůrčí energii a odhodlání. Je ideální čas začít uskutečňovat plány, které jste si stanovili. Zaměřte se na drobné kroky, které vás posunou vpřed.";
                    break;
                case "První čtvrť":
                    messageForPhase = "První čtvrť Měsíce vám dnes přináší energii potřebnou k překonání překážek. Je čas podniknout konkrétní kroky k vašim cílům. Budete-li čelit výzvám, vězte, že máte vnitřní sílu je překonat.";
                    break;
                case "Dorůstající měsíc":
                    messageForPhase = "Dorůstající Měsíc zesiluje vaši manifestační sílu. Cokoli dnes tvoříte a budujete, má potenciál růst. Vaše emoce mohou být intenzivnější, využijte to pro hlubší pochopení svých tužeb.";
                    break;
                case "Úplněk":
                    messageForPhase = "Úplněk dnes osvětluje vše, co bylo skryto. Je čas pro vnitřní prozření a dokončení projektů. Vaše intuice je na vrcholu, naslouchejte jí. Může se objevit emocionální intenzita - přijměte ji jako příležitost k transformaci.";
                    break;
                case "Ubývající měsíc":
                    messageForPhase = "Energie ubývajícího Měsíce vás dnes vede k uvolnění a odevzdání. Je ideální čas pro reflexi, zpracování emocí a odpuštění. Praktikujte vděčnost za vše, co máte, a uvolněte to, co vám již neslouží.";
                    break;
                case "Poslední čtvrť":
                    messageForPhase = "Poslední čtvrť nabízí prostor pro uzavření kapitol a odpuštění. Zaměřte se na to, co chcete uvolnit ze svého života. Je vhodný čas pro úklid - jak fyzický, tak emocionální. Vaše intuice je silná - naslouchejte signálům svého těla.";
                    break;
                case "Ubývající srpek":
                    messageForPhase = "Ubývající srpek podporuje odpoutání a vnitřní očistu. Dnes je ideální čas pro zklidnění, introspekci a meditaci. Neplánujte důležitá rozhodnutí, ale spíše zpracujte minulé zkušenosti.";
                    break;
                default:
                    messageForPhase = "Dnešní den přináší kosmické energie, které podporují vaši intuici a kreativitu. Naslouchejte svému vnitřnímu hlasu a zůstaňte otevření novým příležitostem. Věnujte čas vlastní harmonii a propojení s přírodou.";
            }
            
            this.cosmicMessage.textContent = messageForPhase;
            this.cosmicMessage.style.opacity = '1';
            this.cosmicMessage.style.transform = 'translateY(0)';
            console.log("DailyNotificationCard: Fallback nastavení kosmické zprávy");
        }
    }

    calculateBasicMoonPhase(date) {
        // Jednoduchý výpočet fáze Měsíce - zjednodušený algoritmus
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        
        if (month < 3) {
            year--;
            month += 12;
        }
        
        // Přibližný výpočet podle algoritmu Johna Conwaye
        const c = 365.25 * year;
        const e = 30.6 * month;
        const jd = c + e + day - 694039.09; // Julian Date
        const days = jd / 29.53; // Synodický měsíc
        
        // Normalizace na interval 0-1
        return days - Math.floor(days);
    }

    getMoonPhaseName(phase) {
        if (phase < 0.05 || phase > 0.95) {
            return "Nov";
        } else if (phase < 0.18) {
            return "Dorůstající srpek";
        } else if (phase < 0.32) {
            return "První čtvrť";
        } else if (phase < 0.43) {
            return "Dorůstající měsíc";
        } else if (phase < 0.57) {
            return "Úplněk";
        } else if (phase < 0.68) {
            return "Ubývající měsíc";
        } else if (phase < 0.82) {
            return "Poslední čtvrť";
        } else {
            return "Ubývající srpek";
        }
    }

    updateMoonPhaseVisual(phase) {
        if (!this.moonPhaseVisual) return;
        
        // Odstranění předchozích tříd
        this.moonPhaseVisual.className = 'moon-circle';
        
        // Přidání třídy podle fáze (0 = nov, 0.5 = úplněk)
        if (phase < 0.05 || phase > 0.95) {
            this.moonPhaseVisual.classList.add('new-moon');
        } else if (phase < 0.18) {
            this.moonPhaseVisual.classList.add('waxing-crescent');
        } else if (phase < 0.32) {
            this.moonPhaseVisual.classList.add('first-quarter');
        } else if (phase < 0.43) {
            this.moonPhaseVisual.classList.add('waxing-gibbous');
        } else if (phase < 0.57) {
            this.moonPhaseVisual.classList.add('full-moon');
        } else if (phase < 0.68) {
            this.moonPhaseVisual.classList.add('waning-gibbous');
        } else if (phase < 0.82) {
            this.moonPhaseVisual.classList.add('last-quarter');
        } else {
            this.moonPhaseVisual.classList.add('waning-crescent');
        }
    }

    initGeolocation() {
        if (!this.locationDisplay || !this.locationUpdateBtn) return;
        
        // Aktivní vyžádání geolokace při inicializaci
        this.requestGeolocation();
        
        // Přidání event listeneru pro manuální aktualizaci
        this.locationUpdateBtn.addEventListener('click', () => {
            this.requestGeolocation();
        });
    }
    
    requestGeolocation() {
        if (!navigator.geolocation) {
            this.locationDisplay.textContent = 'Geolokace není podporována';
            return;
        }
        
        this.locationDisplay.textContent = 'Získávám polohu...';
        
        // Vynutíme vyžádání povolení pro geolokaci ihned při načtení
        setTimeout(() => {
            // Zobrazení dialogu pro povolení geolokace
            navigator.geolocation.getCurrentPosition(
                // Úspěch
                async (position) => {
                    try {
                        console.log("DailyNotificationCard: Geolokace získána", position.coords);
                        const response = await fetch(
                            `api/geocode.php?lat=${position.coords.latitude}&lng=${position.coords.longitude}`
                        );
                        const data = await response.json();
                        
                        if (data.success) {
                            this.locationDisplay.textContent = data.city;
                            console.log("DailyNotificationCard: Město nastaveno", data.city);
                            // Přidáme animaci pro zvýraznění aktualizace
                            this.locationDisplay.classList.add('location-updated');
                            setTimeout(() => {
                                this.locationDisplay.classList.remove('location-updated');
                            }, 2000);
                        } else {
                            this.locationDisplay.textContent = 'Chyba při získávání lokace';
                        }
                    } catch (error) {
                        console.error('Chyba geolokace:', error);
                        this.locationDisplay.textContent = 'Chyba při získávání lokace';
                    }
                },
                // Chyba
                (error) => {
                    console.error('Chyba geolokace:', error);
                    
                    // Různé chybové hlášky podle typu chyby
                    switch(error.code) {
                        case error.PERMISSION_DENIED:
                            this.locationDisplay.textContent = 'Přístup k poloze zamítnut';
                            break;
                        case error.POSITION_UNAVAILABLE:
                            this.locationDisplay.textContent = 'Poloha není dostupná';
                            break;
                        case error.TIMEOUT:
                            this.locationDisplay.textContent = 'Vypršel čas pro získání polohy';
                            break;
                        default:
                            this.locationDisplay.textContent = 'Chyba při získávání polohy';
                    }
                },
                // Nastavení
                {
                    enableHighAccuracy: false, // Nižší přesnost, ale rychlejší
                    timeout: 10000,  // 10 sekund
                    maximumAge: 600000 // 10 minut cache
                }
            );
        }, 300); // Krátká prodleva pro zajištění správného načtení komponenty
    }

    scheduleNextUpdate() {
        // Výpočet času do půlnoci
        const now = new Date();
        const tomorrow = new Date(now);
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);
        
        const timeToMidnight = tomorrow - now;
        
        // Nastavení časovače pro aktualizaci o půlnoci
        setTimeout(() => {
            // Načtení nového obsahu
            this.loadDailyContent();
            
            // Nastavení dalšího časovače pro následující den
            this.scheduleNextUpdate();
        }, timeToMidnight);
        
        console.log(`Naplánována aktualizace za ${Math.floor(timeToMidnight/1000/60)} minut`);
    }

    // Nová metoda pro formátování aktuálního data
    formatCurrentDate(date) {
        // Názvy dnů v týdnu
        const days = ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'];
        
        // Názvy měsíců
        const months = [
            'ledna', 'února', 'března', 'dubna', 'května', 'června', 
            'července', 'srpna', 'září', 'října', 'listopadu', 'prosince'
        ];
        
        // Formátování data
        const dayName = days[date.getDay()];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        
        return {
            dayName,
            fullDate: `${day}. ${month} ${year}`
        };
    }
}

// Exportovat třídu pro použití v hlavním skriptu
export default DailyNotificationCard; 